import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import lang from "../../langs";
import { connect } from "react-redux";
import { verifyUser} from "../../actions/auth";
import { verifyValidate } from "../../validations/authValidate";
import { createNotification, pageTitle, cacheStore } from "../../utils/helpers";
import Document from "../layout/Document";
import Logo from "../../img/logo.png";

class Verify extends Component {
  constructor(props) {
    super(props);

    this.startMin = 10;
    this.state = {
      code: "",
      errors: {},
      seconds: '00', 
      minutes: this.startMin,
      timeover:false,
      running:false,
      xpired:false,
      locked:false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.tick = this.tick.bind(this)
    this.secondsRemaining = null
    this.intervalHandle = null
    // method that triggers the countdown functionality
    this.startCountDown = this.startCountDown.bind(this)
    
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if( cacheStore().getItem('start2fa') ) {
      this.startCountDown()
    } else {
      createNotification("warning", lang("auth.timeover"));
      this.props.history.push("/");
    }
   
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  tick() {
    if( this.state.xpired ) {
      return
    }

    let min = Math.floor(this.secondsRemaining / 60);
    let sec = this.secondsRemaining - (min * 60)
    
    if (sec < 10) {
      this.setState({
        seconds: "0" + sec
      })
    } else {
      this.setState({
        seconds: sec,
      })
    }
    
    if (min < 10) {
      this.setState({
        minutes: "0" + min
      })
    } else {
      this.setState({
        minutes:min
      })
    }

    if (min === 0 & sec === 0) {
      clearInterval(this.intervalHandle);
      cacheStore().removeItem('timeverify')
      cacheStore().removeItem('start2fa')
      this.setState({running:false, timeover:true, locked:true})
      setTimeout(()=>{
        createNotification("warning", lang("auth.timeover"));
        this.props.history.push("/dashboard");
      }, 3000)
     
    } else {
      cacheStore().setItem('timeverify', this.secondsRemaining)
      this.setState({running:true})
    }
    console.log(this.secondsRemaining)
    this.secondsRemaining--
  }

  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.state.minutes;
  
    if( cacheStore().getItem('timeverify') ) {
      this.secondsRemaining = cacheStore().getItem('timeverify');
    } else {
      this.secondsRemaining = time * 60;
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const userData = {
      code: this.state.code,
    };

    try {
      await verifyValidate(userData);
    } catch (err) {
      this.setState({ errors: err });
      createNotification(
        "error",
        _.map(err, val => {
          return val;
        }).join("\n\n\n")
      );
      return false;
    }

    this.props
      .verifyUser(userData)
      .then(res => {
        createNotification("success", lang("success.login"));
        clearInterval(this.intervalHandle);
        this.setState({running:false, locked:true})
      })
      .catch(err => {
        if (err && typeof err.response !== undefined) {
          const error = err.response;
          if ( _.has(error, "status") && (error.status == 400) ) {
            createNotification("warning", error.data.messages);
          } else if ( _.has(error, "status") && (error.status == 401) ) {

            clearInterval(this.intervalHandle);
            cacheStore().removeItem('timeverify')
            cacheStore().removeItem('start2fa')
            
            this.setState({running:false, xpired:true, locked:true})

            setTimeout(()=>{
              createNotification("warning", error.data.messages);
              this.props.history.push("/");
            }, 3000)
            
          } else {
            createNotification("error", lang("error.server_unknown"));
          }
        } else {
          createNotification("error", lang("error.server_unknown"));
        }
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (this.state.errors) {
      if (this.state.code && this.state.errors.code) {
        this.setState({ errors: { code: null } });
      }
      
    }
  }

  render() {
    const { errors } = this.state;
    let progressw
    console.log(this.secondsRemaining)
    if( this.secondsRemaining > 0 ) {
      progressw =  (Math.floor(this.secondsRemaining) / (this.startMin * 60)) * 100
    } else {
      progressw = 100
    }
    
    return (
      <Document title={pageTitle("Login")} className="pageLogin">
        <div id="bodyLogin">
          <div className="align-self-center text-center mb-4">
            <img src={Logo} alt="Onehourloan" />
          </div>

          <div className="shadow-sm p-4 mb-5 bg-white rounded">
            <div className="col align-self-center w-auto-xs mb-3">
              <div className="text-color">
                <div className="text-uppercase text-muted text-center mb-4 text-sm">
                  Please enter <strong>a verification code</strong> that has been sent to your email!
                </div>

                {this.state.timeover && <div className="text-center">
                  <h3>Time is Over!</h3>
                  <p>The page will be redirected automatically to login screen</p>
                </div>}

                {this.state.xpired && <div className="text-center">
                  <h3>Verification code is expired!</h3>
                  <p>The page will be redirected automatically to login screen</p>
                </div>}

                {(!this.state.locked) && <form name="formLogin" noValidate>
                  
                  {this.state.running && <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{ width: progressw + '%'}} aria-valuenow={progressw} aria-valuemin="0" aria-valuemax="100">{Math.floor(progressw)}%</div>
                  </div>}

                  <div className="form-label-group mb-4">
                    <input
                      type="text"
                      name="code"
                      id="code"
                      placeholder="Enter Code"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.code
                      })}
                      minLength={4}
                      maxLength={4}
                      style={{height:60, fontSize:30,textAlign:"center", letterSpacing:8}}
                      onChange={this.onChange}
                      value={this.state.code}
                    />
                    {errors.code && (
                      <div className="invalid-feedback">{errors.code}.</div>
                    )}
                  </div>

                  <button
                    type="button"
                    id="verifSubmit"
                    disabled={this.props.loaded ? true : false}
                    onClick={this.onSubmit}
                    className="btn btn-info btn-block btn-lg"
                  >
                    {this.props.loaded ? "Loading..." : "Verify"}
                  </button>
                </form>}
              </div>
            </div>
          </div>
        </div>
      </Document>
    );
  }
}

Verify.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false
});

export default connect(
  mapStateToProps,
  { verifyUser }
)(Verify);
