import {
  FETCH_APPLICATIONS,
  FILTER_APPLICATIONS,
  PUT_NOTE_APPLICATION,
  BLACKLIST_PHONES
} from "../actions/types";

const initialState = {
  list: [],
  blacklist:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_APPLICATIONS:
      return {
        ...state,
        list: action.payload
      };
    case BLACKLIST_PHONES:
        return {
          ...state,
          blacklist: action.payload
        };
    default:
      return state;
  }
}
