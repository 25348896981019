import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import classnames from 'classnames'
import { updateUser } from '../../actions/user';
import { createNotification, pageTitle, urlExists, inputNumberOnly, encapsulateErrors } from '../../utils/helpers'
import { Cardbox } from '../ui'
import { updateProfileValidate } from '../../validations/user'
import DocumentException from '../common/DocumentException'
import _ from 'lodash'


class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: 'Edit User',
            name: '',
            phone: '',
            email: '',
            errors: {}
        }

        this.onChange = this.onChange.bind(this)
        this.inputHp = this.inputHp.bind(this)
        this.onSwitchChange = this.onSwitchChange.bind(this)
    }

    async componentDidMount() {
        const user = this.props.user
        this.setState({
            name: user.name,
            phone: user.no_hp,
            email: user.email
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        let userData = {
            name: this.state.name,
            no_hp: this.state.phone,
            email: this.state.email,
            role: this.props.user.role
        };

        try {
            await updateProfileValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', _.join(_.toArray(err), "\n"))
            return false;
        }

        this.props.updateUser(this.props.user.id, userData, true)
            .then(res => {
                createNotification('success', res.messages)
                this.props.history.replace("/account")
            })
            .catch(err => {
                this.setState({ errors: encapsulateErrors(this.props.errors.data) })
                createNotification('error', 'Failed update user! Please attention for errors')
            })
    }

    inputHp(e) {
        inputNumberOnly(e)
    }

    onSwitchChange(e) {
        this.setState({
            [e.target.name]: !this.state[`${e.target.name}`]
        })
    }

    render() {

        const errors = this.state.errors

        if (this.props.user == null) {
            return null
        }

        return (
            <DocumentException
                title={pageTitle('Account')}
                className="accountPage"
                history={this.props.history}
                url={this.props.location.pathname} >
                <div className="container">
                    <div className="row justify-content-md-center mt-3">
                        <div className="col-sm-12 col-lg-8">
                            <div className="text-center mb-3">
                                <h4>Update Profile</h4>
                            </div>

                            <Cardbox>
                                <div>
                                    <div className="form-label-group mb-4">
                                        <label className="font-bold" htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            disabled={this.props.loader === true}
                                            className={classnames('form-control form-control-md', {
                                                'is-invalid': _.has(errors, 'name') ? errors.name : false
                                            })}
                                            autoFocus
                                            onChange={this.onChange}
                                            value={this.state.name}
                                        />
                                        {_.has(errors, 'name') && errors.name && <div className="invalid-feedback">{errors.name}.</div>}
                                    </div>

                                    <div className="form-label-group mb-4">
                                        <label className="font-bold" htmlFor="phone">No HP</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            onKeyPress={this.inputHp}
                                            disabled={this.props.loader === true}
                                            className={classnames('form-control form-control-md', {
                                                'is-invalid': _.has(errors, 'phone') ? errors.phone : false
                                            })}
                                            onChange={this.onChange}
                                            value={this.state.phone}
                                        />
                                        {_.has(errors, 'phone') && errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                    </div>

                                    <div className="form-label-group mb-4">
                                        <label className="font-bold" htmlFor="email">Email Address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            disabled={this.props.loader === true}
                                            className={classnames('form-control form-control-md', {
                                                'is-invalid': _.has(errors, 'email') ? errors.email : false
                                            })}
                                            onChange={this.onChange}
                                            value={this.state.email}
                                        />
                                        {_.has(errors, 'email') && errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>

                                </div>
                            </Cardbox>

                            <div className="form-label-group mb-4 mt-4">
                                <button disabled={this.props.loader === true} onClick={this.onSubmit.bind(this)} type="button" className="btn btn-danger btn-block btn-lg">
                                    {this.props.loader ? 'Loading...' : 'Update User'}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </DocumentException>
        )
    }
}

EditProfile.propTypes = {
    loadingRequest: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    loader: PropTypes.bool,
    user: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
    merchant: state.auth.user.merchant,
    errors: state.errors,
    loadingRequest: state.loadingRequest,
    loader: state.formLoader,
    user: state.auth.user.user
});

export default connect(mapStatetoProps, {
    updateUser,
})(EditProfile);