
import React from 'react'
import { useTable, useFilters, useExpanded } from 'react-table'

export default function Table({ columns: userColumns, data, renderRowSubComponent }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        page,
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        flatColumns,
        state: [{ expanded }],
    } = useTable({
        columns: userColumns,
        data,
    }, useExpanded, useFilters)
    // Render the UI for your table
    return (
        <>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.map(
                    (row, i) =>
                        prepareRow(row) || (
                            <>
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                                {row.isExpanded ? (
                                    <tr>
                                        <td style={{background:"#fff"}} colSpan={flatColumns.length}>
                                            {/*
                                        Inside it, call our renderRowSubComponent function. In reality,
                                        you coul pass whatever you want as props to
                                        a component like this, including the entire
                                        table instance. But for this example, we'll just
                                        pass the row
                                        */}
                                            {renderRowSubComponent({ row })}
                                        </td>
                                    </tr>
                                ) : null}
                            </>
                        )
                )}
            </tbody>
            
        </>
    )
}
